/**
 * @generated SignedSource<<7503a925a894f573a7db1e4580938220>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductAdminActionKind = "publish" | "add_curriculum_content" | "invite_instructors" | "review_applications" | "set_up_automations" | "review_submissions" | "customize_registration_page" | "create_certificate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductAdminDashboardActionFragment$data = {
  readonly id: string;
  readonly kind: ProductAdminActionKind;
  readonly ordering: number;
  readonly viewerHasDismissed: boolean;
  readonly isComplete: boolean;
  readonly count: number;
  readonly contentUsage: {
    readonly id: string;
    readonly content: {
      readonly name: string | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useContentUsagePathContentUsageFragment">;
  } | null;
  readonly " $fragmentType": "ProductAdminDashboardActionFragment";
};
export type ProductAdminDashboardActionFragment$key = {
  readonly " $data"?: ProductAdminDashboardActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductAdminDashboardActionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductAdminDashboardActionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordering",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasDismissed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsage",
      "kind": "LinkedField",
      "name": "contentUsage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "content",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentUsageUtils_useContentUsagePathContentUsageFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductAdminAction",
  "abstractKey": null
};
})();

(node as any).hash = "f7394cc11b2b419a79d3cc2d9a0adbce";

export default node;
