import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductAdminInfoToolbarFragment$key } from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/__generated__/ProductAdminInfoToolbarFragment.graphql"

import ProductTypeTag from "@/product/common/type-tag/ProductTypeTag"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import ProductUtils from "@/product/util/productUtils"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ProductCountdownString from "@components/countdown/ProductCountdownString"
import { DiscoIcon, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { isPast } from "date-fns"
import pluralize from "pluralize"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: ProductAdminInfoToolbarFragment$key
}

function ProductAdminInfoToolbar(props: Props) {
  const { dashboardBlockKey } = props
  const activeProduct = useActiveProduct()!
  const timeZone = useUserTimezone()
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"))
  const membersLabel = useLabel("organization_member")
  const classes = useStyles()

  const [isNowStarted, setIsNowStarted] = useState(
    isPast(new Date(activeProduct.startsAt!))
  )
  const hasEnded = activeProduct.endsAt && isPast(new Date(activeProduct.endsAt))

  const startDateText = formatDateWithOptions({
    timeZone,
    format: isMdScreen
      ? DATE_FORMAT.DEFAULT_MONTH_AND_DAY
      : DATE_FORMAT.DEFAULT_FULL_MONTH_WITHOUT_YEAR,
  })(new Date(activeProduct.startsAt!))

  const endDateText = formatDateWithOptions({
    timeZone,
    format: isMdScreen
      ? DATE_FORMAT.DEFAULT_MONTH_AND_DAY
      : DATE_FORMAT.DEFAULT_FULL_MONTH,
  })(new Date(activeProduct.endsAt!))

  const block = useFragment<ProductAdminInfoToolbarFragment$key>(
    graphql`
      fragment ProductAdminInfoToolbarFragment on ProductAdminDashboardBlock {
        id
        showDates
        showCountdown
        showAvailability
        product {
          type
          childProducts {
            totalCount
          }
          ...ProductTypeTagFragment
        }
      }
    `,
    dashboardBlockKey
  )
  const isPathway = block.product?.type === "pathway"
  const courseLabel = useAdminProductLabel("course")

  return (
    <div className={classes.infoBar}>
      {/* Type Tag */}
      {block.product && (
        <div className={classes.option}>
          <DiscoIcon
            icon={isPathway ? "book" : "tag"}
            color={theme.palette.text.secondary}
            width={20}
            height={20}
          />
          {isPathway ? (
            <DiscoText
              variant={"body-sm-500"}
              color={"groovy.neutral.600"}
              display={"inline"}
            >
              {pluralize(
                courseLabel.singular,
                block.product.childProducts.totalCount,
                true
              )}
            </DiscoText>
          ) : (
            <ProductTypeTag
              testid={"ProductAdminInfoToolbar.productTag"}
              productKey={block.product}
              tagProps={{ className: classes.productTag }}
            />
          )}
        </div>
      )}

      {/* Dates */}
      {block.showDates && activeProduct.startsAt && activeProduct.endsAt && (
        <div
          className={classes.option}
          data-testid={"ProductAdminInfoToolbar.productDates"}
        >
          <DiscoIcon
            icon={"calendar"}
            color={theme.palette.text.secondary}
            width={20}
            height={20}
          />
          <DiscoText
            variant={"body-sm-500"}
            color={"groovy.neutral.600"}
            display={"inline"}
          >{`${startDateText} - ${endDateText}`}</DiscoText>
        </div>
      )}

      {/* Countdown */}
      {block.showCountdown && activeProduct.startsAt && (
        <div className={classes.option} data-testid={"ProductAdminInfoToolbar.countDown"}>
          <DiscoIcon
            icon={"time"}
            color={theme.palette.text.secondary}
            width={20}
            height={20}
          />
          <DiscoText
            variant={"body-sm-500"}
            color={"groovy.neutral.600"}
            display={"inline"}
            className={classes.countdown}
          >
            {hasEnded ? (
              "Ended"
            ) : isNowStarted ? (
              "Live Now"
            ) : (
              <ProductCountdownString
                onEnd={onIsLive}
                startsAt={new Date(activeProduct.startsAt!)}
                prefixText={isMdScreen ? "In" : "Starts in"}
                textProps={{ variant: "body-sm-500", color: "groovy.neutral.600" }}
              />
            )}
          </DiscoText>
        </div>
      )}

      {/* Status */}
      {block.showAvailability && (
        <div
          className={classes.option}
          data-testid={"ProductAdminInfoToolbar.productStatus"}
        >
          <DiscoIcon
            icon={ProductUtils.getAvailabilityIcon(
              activeProduct.registrationAvailability
            )}
            color={theme.palette.text.secondary}
            width={20}
            height={20}
          />
          <DiscoText
            variant={"body-sm-500"}
            color={"groovy.neutral.600"}
            display={"inline"}
          >
            {ProductUtils.getAvailabilityLabel(
              activeProduct.registrationAvailability,
              membersLabel
            )}
          </DiscoText>
        </div>
      )}
    </div>
  )

  function onIsLive() {
    setIsNowStarted(true)
  }
}

const useStyles = makeUseStyles((theme) => ({
  infoBar: {
    display: "flex",
    gap: theme.spacing(3),
    alignItems: "center",
  },
  option: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  productTag: {
    borderRadius: theme.measure.borderRadius.medium,
  },
  countdown: {
    "& div": {
      ...theme.typography["body-sm"],
      color: theme.palette.text.secondary,
    },
  },
}))

export function ProductAdminInfoToolbarSkeleton() {
  const classes = useStyles()
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <div className={classes.infoBar}>
      {range(4).map((i) => (
        <div key={i} className={classes.option}>
          <Skeleton variant={"circle"} width={20} height={20} />
          <DiscoTextSkeleton width={isMdScreen ? "70px" : "125px"} />
        </div>
      ))}
    </div>
  )
}

export default Relay.withSkeleton({
  component: ProductAdminInfoToolbar,
  skeleton: ProductAdminInfoToolbarSkeleton,
})
