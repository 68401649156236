import BannerDashboardBlock from "@/dashboard/blocks/kinds/BannerDashboardBlock"
import ChannelsDashboardBlock from "@/dashboard/blocks/kinds/ChannelsDashboardBlock"
import CollectionFolderDashboardBlock from "@/dashboard/blocks/kinds/CollectionFolderDashboardBlock"
import CommunityWelcomeHeroDashboardBlock from "@/dashboard/blocks/kinds/CommunityWelcomeHeroDashboardBlock"
import ContentDashboardBlock from "@/dashboard/blocks/kinds/ContentDashboardBlock"
import CurriculumDashboardBlock from "@/dashboard/blocks/kinds/CurriculumDashboardBlock"
import EventsDashboardBlock from "@/dashboard/blocks/kinds/EventsDashboardBlock"
import ExperienceDetailsDashboardBlock from "@/dashboard/blocks/kinds/ExperienceDetailsDashboardBlock"
import FeaturedItemsDashboardBlock from "@/dashboard/blocks/kinds/FeaturedItemsDashboardBlock"
import FeedDashboardBlock from "@/dashboard/blocks/kinds/FeedDashboardBlock"
import LeaderboardDashboardBlock from "@/dashboard/blocks/kinds/LeaderboardDashboardBlock"
import MembersListDashboardBlock from "@/dashboard/blocks/kinds/MembersListDashboardBlock"
import PathwaySequenceDashboardBlock from "@/dashboard/blocks/kinds/pathway-sequence/PathwaySequenceDashboardBlock"
import ProductAdminDashboardBlock from "@/dashboard/blocks/kinds/ProductAdminDashboardBlock"
import RichTextDashboardBlock from "@/dashboard/blocks/kinds/RichTextDashboardBlock"
import WelcomeBannerDashboardBlock from "@/dashboard/blocks/kinds/WelcomeBannerDashboardBlock"
import { DashboardBlockItemFragment$key } from "@/dashboard/blocks/__generated__/DashboardBlockItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import ContinueYourProductsDashboardBlock from "./kinds/continue-your-products/ContinueYourProductsDashboardBlock"
import RecentlyViewedDashboardBlock from "./kinds/recently_viewed/RecentlyViewedDashboardBlock"

interface DashboardBlockItemProps extends TestIDProps {
  dashboardBlockKey: DashboardBlockItemFragment$key
  index?: number
}

function DashboardBlockItem(props: DashboardBlockItemProps) {
  const { dashboardBlockKey, index } = props
  const permissions = useActiveProductOrOrganizationPermissions()

  const block = useFragment<DashboardBlockItemFragment$key>(
    graphql`
      fragment DashboardBlockItemFragment on DashboardBlock {
        id
        kind
        position
        ...LeaderboardDashboardBlockFragment
        ...WelcomeBannerDashboardBlockFragment
        ...FeaturedItemsDashboardBlockFragment
        ...MembersListDashboardBlockFragment
        ...RichTextDashboardBlockFragment
        ...ContentDashboardBlockFragment
        ...ExperienceDetailsDashboardBlockFragment
        ...EventsDashboardBlockFragment
        ...FeedDashboardBlockFragment
        ...CurriculumDashboardBlockFragment
        ...ChannelsDashboardBlockFragment
        ...BannerDashboardBlockFragment
        ...CollectionFolderDashboardBlockFragment
        ...CommunityWelcomeHeroDashboardBlockFragment
        ...ProductAdminDashboardBlockFragment
        ...PathwaySequenceDashboardBlockFragment
        ...ContinueYourProductsDashboardBlockFragment
        ...RecentlyViewedDashboardBlockFragment
      }
    `,
    dashboardBlockKey
  )

  if (block.kind === "members_list" && !permissions.has("organization_members.read"))
    return null
  if (block.kind === "product_admin" && !permissions.has("dashboard.manage")) return null

  const blockConfig = BLOCK_COMPONENTS[block.kind as keyof typeof BLOCK_COMPONENTS]
  if (!blockConfig) return null
  const Component = blockConfig[block.position as keyof typeof blockConfig]
  if (!Component) return null

  return <Component dashboardBlockKey={block} index={index} />
}

const BLOCK_COMPONENTS = {
  leaderboard: {
    main: LeaderboardDashboardBlock,
    side: LeaderboardDashboardBlock,
  },
  welcome_banner: {
    main: WelcomeBannerDashboardBlock,
    side: WelcomeBannerDashboardBlock,
  },
  members_list: {
    main: MembersListDashboardBlock,
    side: MembersListDashboardBlock,
  },
  upcoming_events: {
    main: EventsDashboardBlock,
    side: EventsDashboardBlock,
  },
  feed: {
    main: FeedDashboardBlock,
    side: FeedDashboardBlock,
  },
  featured_items: {
    main: FeaturedItemsDashboardBlock,
    side: FeaturedItemsDashboardBlock,
  },
  experience_details: {
    main: ExperienceDetailsDashboardBlock,
    side: ExperienceDetailsDashboardBlock,
  },
  rich_text: {
    main: RichTextDashboardBlock,
    side: RichTextDashboardBlock,
  },
  content: {
    main: ContentDashboardBlock,
    side: ContentDashboardBlock,
  },
  collection_folder: {
    main: CollectionFolderDashboardBlock,
    side: CollectionFolderDashboardBlock,
  },
  curriculum: {
    main: CurriculumDashboardBlock,
    side: CurriculumDashboardBlock,
  },
  channels: {
    main: ChannelsDashboardBlock,
    side: ChannelsDashboardBlock,
  },
  banner: {
    main: BannerDashboardBlock,
    side: BannerDashboardBlock,
  },
  community_welcome_hero: {
    main: CommunityWelcomeHeroDashboardBlock,
    side: CommunityWelcomeHeroDashboardBlock,
  },
  product_admin: {
    main: ProductAdminDashboardBlock,
    side: ProductAdminDashboardBlock,
  },
  pathway_sequence: {
    main: PathwaySequenceDashboardBlock,
    side: PathwaySequenceDashboardBlock,
  },
  continue_your_products: {
    main: ContinueYourProductsDashboardBlock,
    side: ContinueYourProductsDashboardBlock,
  },
  recently_viewed: {
    main: RecentlyViewedDashboardBlock,
    side: RecentlyViewedDashboardBlock,
  },
}

export function DashboardBlockItemSkeleton() {
  const theme = useTheme()
  return (
    <Skeleton
      variant={"rect"}
      height={"386px"}
      style={{
        borderRadius: theme.measure.borderRadius.big,
      }}
    />
  )
}

export default Relay.withSkeleton({
  component: DashboardBlockItem,
  skeleton: DashboardBlockItemSkeleton,
})
