import { useActiveProduct } from "@/core/context/ActiveProductContext"
import Format from "@/core/format/format"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ProductAdminStatisticsReportCard, {
  ProductAdminStatisticsReportCardSkeleton,
} from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/ProductAdminStatisticsReportCard"
import { ProductAdminDashboardStatisticsQuery } from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/__generated__/ProductAdminDashboardStatisticsQuery.graphql"
import generateCoursePaths from "@/organization/common/sidebar/my-experiences-list/util/generateCoursePaths"
import {
  useAdminProductLabel,
  useProductMemberLabel,
} from "@/product/util/hook/useProductLabel"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoStatisticsTag from "@disco-ui/tag/statistics/DiscoStatisticsTag"
import { Grid, useTheme } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { graphql, useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router-dom"

interface Props {
  blockId: GlobalID
}

function ProductAdminDashboardStatistics(props: Props) {
  const { blockId } = props
  const classes = useStyles()
  const { aggregateProductReports } = useFeatureFlags()
  const theme = useTheme()
  const isDark = theme.palette.type === "dark"
  const activeProduct = useActiveProduct()!
  const COURSE_PATHS = generateCoursePaths(activeProduct.slug)

  // this is written as query currently due to aggregateProductReports feature flag
  // can be changed to fragment when feature flag is turned on
  const { block } = useLazyLoadQuery<ProductAdminDashboardStatisticsQuery>(
    graphql`
      query ProductAdminDashboardStatisticsQuery(
        $id: ID!
        $shouldQueryAvgEngagementScore: Boolean!
        $roles: [ProductRole!]
      ) {
        block: node(id: $id) {
          ... on ProductAdminDashboardBlock {
            showRegisteredUsers
            showDailyActiveUsers
            showAvgEngagementScore
            showAvgCurriculumProgress
            showAvgCompletion

            product {
              type
              productMemberships(roles: $roles) {
                totalCount
              }
              memberActivityReport {
                thisWeek
                lastWeek
              }

              averageEngagementScore @include(if: $shouldQueryAvgEngagementScore)

              curriculumReport {
                averageCurriculumProgress
              }
              hasCurriculumApp: hasProductApp(kind: curriculum, isActive: true)

              pathwayProgressReport {
                completed
              }
            }
          }
        }
      }
    `,
    {
      id: blockId,
      shouldQueryAvgEngagementScore: Boolean(aggregateProductReports),
      roles: activeProduct.adminCanLearnMode ? undefined : ["member"],
    }
  )

  const memberLabel = useProductMemberLabel(activeProduct.type)
  const productLabel = useAdminProductLabel(activeProduct.type)

  if (!block || !block!.product) return null
  const isPathway = block.product.type === "pathway"

  const PRODUCT_REPORTS = isPathway
    ? generatePath(ROUTE_NAMES.ADMIN.INSIGHTS.PATHWAYS.PATHWAY, {
        productSlug: activeProduct.slug,
      })
    : generatePath(ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCT, {
        productSlug: activeProduct.slug,
      })

  return (
    <Grid container spacing={2}>
      {/* Registered Users */}
      {block.showRegisteredUsers && (
        <Grid item xs>
          <ProductAdminStatisticsReportCard
            testid={"ProductAdminStatisticsReportCard.registeredUsers"}
            value={block.product.productMemberships.totalCount}
            subtitle={`Registered ${memberLabel.plural}`}
            to={COURSE_PATHS.MEMBERS.LIST}
            icons={
              <DiscoIcon
                icon={"external-link"}
                color={
                  isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.grey[500]
                }
              />
            }
            tooltipText={`The total number of ${memberLabel.plural} registered all-time.`}
          />
        </Grid>
      )}

      {/* Active Users */}
      {block.showDailyActiveUsers && (
        <Grid item xs className={classes.gridItem}>
          <ProductAdminStatisticsReportCard
            testid={"ProductAdminStatisticsReportCard.dailyActiveUsers"}
            value={block.product.memberActivityReport.thisWeek}
            to={PRODUCT_REPORTS}
            subtitle={`Active ${memberLabel.plural}`}
            tags={
              block.product.memberActivityReport.thisWeek -
                block.product.memberActivityReport.lastWeek !==
                0 && block.product.memberActivityReport.lastWeek ? (
                // calculating percent change (thisWeek - lastWeek) / lastWeek
                <DiscoStatisticsTag
                  textVariant={"body-sm-500"}
                  number={
                    block.product.memberActivityReport.thisWeek -
                    block.product.memberActivityReport.lastWeek
                  }
                  allTimeNumber={block.product.memberActivityReport.lastWeek}
                  percentage
                  marginTop={0}
                  tagVariant={"round"}
                  tooltipContent={
                    <>
                      <DiscoText className={classes.toolTipText}>
                        {`Last 7 days: ${block.product.memberActivityReport.thisWeek}`}
                      </DiscoText>
                      <DiscoText className={classes.toolTipText}>
                        {`Previous period: ${block.product.memberActivityReport.lastWeek}`}
                      </DiscoText>
                    </>
                  }
                />
              ) : (
                <></>
              )
            }
            icons={
              <DiscoIcon
                icon={"external-link"}
                color={
                  isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.grey[500]
                }
              />
            }
            tooltipText={`${memberLabel.plural} who logged in within the last 7 days.`}
          />
        </Grid>
      )}

      {/* Avg Engagement Score */}
      {aggregateProductReports && block.showAvgEngagementScore && (
        <Grid item xs className={classes.gridItem}>
          <ProductAdminStatisticsReportCard
            testid={"ProductAdminStatisticsReportCard.avgEngagementScore"}
            value={block.product.averageEngagementScore}
            to={PRODUCT_REPORTS}
            subtitle={`Average Engagement Score`}
            icons={
              <DiscoIcon
                icon={"external-link"}
                color={
                  isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.grey[500]
                }
              />
            }
            tooltipText={"The average engagement score over the last 7 days."}
          />
        </Grid>
      )}

      {/* Avg Curriculum Progress */}
      {block.product.hasCurriculumApp && (
        <Grid item xs>
          <ProductAdminStatisticsReportCard
            testid={"ProductAdminStatisticsReportCard.avgCurriculumProgress"}
            value={
              Format.asPercent(
                block.product.curriculumReport.averageCurriculumProgress
              ) || 0
            }
            subtitle={`Average Curriculum Progress`}
            to={PRODUCT_REPORTS}
            icons={
              <DiscoIcon
                icon={"external-link"}
                color={
                  isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.grey[500]
                }
              />
            }
            tooltipText={`The average percentage of ${memberLabel.plural} who completed an item inside a module.`}
          />
        </Grid>
      )}

      {/* Avg Completion - only used by pathways for now */}
      {block.product.pathwayProgressReport && (
        <Grid item xs>
          <ProductAdminStatisticsReportCard
            testid={"ProductAdminStatisticsReportCard.avgCompletion"}
            value={
              Format.asPercent(
                block.product.pathwayProgressReport.completed /
                  block.product.productMemberships.totalCount
              ) || 0
            }
            subtitle={`Average Completion Rate`}
            to={PRODUCT_REPORTS}
            icons={
              <DiscoIcon
                icon={"external-link"}
                color={
                  isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.grey[500]
                }
              />
            }
            tooltipText={`The percentage of ${memberLabel.plural} who completed the ${productLabel.singular}.`}
          />
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeUseStyles((theme) => ({
  toolTipText: {
    ...theme.typography["body-xs"],
    ...theme.typography.modifiers.fontWeight["600"],
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.neutral[100]
        : theme.palette.common.white,
  },
  gridItem: {
    "&:not(:has(*))": {
      display: "none",
    },
  },
}))

export function ProductAdminDashboardStatisticsSkeleton() {
  return (
    <Grid container spacing={2}>
      {range(4).map((i) => (
        <Grid key={i} item xs>
          <ProductAdminStatisticsReportCardSkeleton />
        </Grid>
      ))}
    </Grid>
  )
}

export default Relay.withSkeleton({
  component: ProductAdminDashboardStatistics,
  skeleton: ProductAdminDashboardStatisticsSkeleton,
})
