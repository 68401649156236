import PublishPathwayButton from "@/admin/pathways/PublishPathwayButton"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { ProductAdminDashboardActionCreateMutation } from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/__generated__/ProductAdminDashboardActionCreateMutation.graphql"
import { ProductAdminDashboardActionDeleteMutation } from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/__generated__/ProductAdminDashboardActionDeleteMutation.graphql"
import { ProductAdminDashboardActionFragment$key } from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/__generated__/ProductAdminDashboardActionFragment.graphql"
import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import PublishExperienceButton from "@/product/buttons/PublishProduct/PublishExperienceButton"
import {
  useAdminProductLabel,
  useProductMemberLabel,
} from "@/product/util/hook/useProductLabel"
import Relay from "@/relay/relayUtils"
import { useProductRoleLabel } from "@/role/roleUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText, DiscoTextSkeleton, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { OverridableDiscoButtonProps } from "@disco-ui/button/OverridableDiscoButton"
import { Checkbox, Grid, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { setSearchParams } from "@utils/url/urlUtils"
import pluralize from "pluralize"
import React, { ReactNode } from "react"
import { ConnectionHandler, graphql, useFragment, useMutation } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { RecordSourceSelectorProxy } from "relay-runtime"

interface Props {
  adminActionKey: ProductAdminDashboardActionFragment$key
  testid: string
}

interface ActionConfig {
  leftIcon?: ReactNode
  message: { text: string | number; bold?: boolean; color?: string }[]
  onClick?: () => void
  customActionNode?: ReactNode
}

function ProductAdminDashboardAction(props: Props) {
  const { adminActionKey, testid } = props
  const theme = useTheme()
  const classes = useStyles()
  const instructorLabel = useProductRoleLabel("instructor")
  const history = useHistory()
  const activeProduct = useActiveProduct()!
  const strikeThroughColor =
    theme.palette.type === "dark"
      ? theme.palette.groovy.onDark[200]
      : theme.palette.groovy.neutral[400]
  const isPathway = activeProduct.type === "pathway"
  const productLabel = useAdminProductLabel(activeProduct.type)
  const memberLabel = useProductMemberLabel(activeProduct.type)
  const openRegistrationDrawer = useGlobalDrawer("registration").open
  const openCertificateDrawer = useGlobalDrawer("certificates").open

  const action = useFragment<ProductAdminDashboardActionFragment$key>(
    graphql`
      fragment ProductAdminDashboardActionFragment on ProductAdminAction {
        id
        kind
        ordering
        viewerHasDismissed
        isComplete
        count
        contentUsage {
          id
          content {
            name
          }
          ...ContentUsageUtils_useContentUsagePathContentUsageFragment
        }
      }
    `,
    adminActionKey
  )
  const contentUsagePath = ContentUsageUtils.useContentUsagePath(action.contentUsage)

  const [create, isCreating] = useMutation<ProductAdminDashboardActionCreateMutation>(
    graphql`
      mutation ProductAdminDashboardActionCreateMutation($id: ID!) {
        response: createAdminActionDismissal(id: $id) {
          node {
            viewerHasDismissed
          }
        }
      }
    `
  )

  const [remove, isRemoving] = useMutation<ProductAdminDashboardActionDeleteMutation>(
    graphql`
      mutation ProductAdminDashboardActionDeleteMutation($id: ID!) {
        response: deleteAdminActionDismissal(id: $id) {
          node {
            viewerHasDismissed
          }
        }
      }
    `
  )

  if (!action) return null
  const actionConfig = getActionConfig()
  if (!actionConfig) return null

  const checkboxDisabled = action.isComplete || isCreating || isRemoving
  const actionDismissed = action.isComplete || action.viewerHasDismissed

  return (
    <Grid item xs={12} data-testid={`${testid}.${action.kind}`}>
      {actionConfig.customActionNode || (
        <DiscoContainerButton
          onClick={actionConfig.onClick}
          className={classes.itemContainer}
        >
          {renderAction()}
        </DiscoContainerButton>
      )}
    </Grid>
  )

  function renderAction() {
    return (
      <>
        <div className={classes.ctaContainer}>
          <DiscoTooltip
            disabled={isCreating || isRemoving}
            content={
              action.isComplete
                ? "This action has been completed"
                : action.viewerHasDismissed
                ? "Mark as To Do"
                : "Dismiss"
            }
          >
            <Checkbox
              data-testid={
                checkboxDisabled
                  ? `${testid}.${action.kind}.dismissActionButton.disabled`
                  : `${testid}.${action.kind}.dismissActionButton`
              }
              checked={actionDismissed}
              className={checkboxDisabled ? classes.disabledCheckbox : classes.checkbox}
              onClick={(e) => {
                e.stopPropagation()
                /* can't use disabled or click will bubble through to parent so return early */
                if (checkboxDisabled) return
                handleClick()
              }}
              disableRipple
              color={"default"}
              checkedIcon={
                <DiscoIcon
                  icon={"check-circle"}
                  active
                  width={24}
                  height={24}
                  color={theme.palette.primary.main}
                />
              }
              icon={<span className={classes.radioIcon} />}
            />
          </DiscoTooltip>
          <div className={classes.labelContainer}>
            {!actionDismissed && actionConfig!.leftIcon}
            <DiscoText data-testid={`${testid}.${action.kind}.actionText`}>
              {actionConfig!.message.map((msg) => (
                <span
                  key={msg.text}
                  style={{
                    fontWeight: actionDismissed
                      ? undefined
                      : msg.bold
                      ? "bold"
                      : undefined,
                    color: actionDismissed ? strikeThroughColor : msg.color,
                    textDecoration: actionDismissed ? "line-through" : undefined,
                  }}
                >
                  {`${msg.text} `}
                </span>
              ))}
            </DiscoText>
          </div>
        </div>
      </>
    )
  }

  function getActionConfig(): ActionConfig | null {
    switch (action.kind) {
      case "publish":
        return {
          leftIcon: (
            <DiscoIcon
              icon={"warning"}
              width={24}
              height={24}
              color={theme.palette.groovy.yellow[500]}
            />
          ),
          message: [
            { text: "Publish", bold: true },
            {
              text: `this ${productLabel.singular} to make it visible to all ${memberLabel.plural}`,
            },
          ],
          customActionNode: React.createElement(
            isPathway ? PublishPathwayButton : PublishExperienceButton,
            null,
            (buttonProps: OverridableDiscoButtonProps) => (
              <DiscoContainerButton
                testid={buttonProps.testid}
                onClick={buttonProps.onClick}
                disabled={buttonProps.disabled}
                className={classes.itemContainer}
              >
                {renderAction()}
              </DiscoContainerButton>
            )
          ),
        }
      case "add_curriculum_content":
        return {
          message: [
            { text: "Add Curriculum Content", bold: true },
            { text: `to enrich ${memberLabel.singular} experience` },
          ],
          onClick: () => {
            history.push({
              pathname: generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
                productSlug: activeProduct.slug,
              }),
            })
          },
        }
      case "invite_instructors":
        return {
          message: [
            { text: `Invite ${instructorLabel.plural}`, bold: true },
            { text: `to help you manage the ${productLabel.singular}` },
          ],
          customActionNode: (
            <CreateInvitationButton
              productId={activeProduct.id}
              onlyAdminRoles
              defaultProductRole={"instructor"}
            >
              {(buttonProps) => (
                <DiscoContainerButton {...buttonProps} className={classes.itemContainer}>
                  {renderAction()}
                </DiscoContainerButton>
              )}
            </CreateInvitationButton>
          ),
        }
      case "review_applications":
        return {
          message: [
            { text: "Review", bold: true },
            { text: "pending applications of" },
            { text: action.count, bold: true },
            { text: action.count === 1 ? memberLabel.singular : memberLabel.plural },
          ],
          onClick: () => {
            history.push({
              pathname: generatePath(ROUTE_NAMES.PRODUCT.MEMBERS.APPLICATIONS, {
                productSlug: activeProduct.slug,
              }),
            })
          },
        }
      case "set_up_automations":
        return {
          message: [
            { text: "Set up automations", bold: true },
            { text: `to encourage ${memberLabel.singular} engagement` },
          ],
          onClick: () => {
            history.push({ pathname: ROUTE_NAMES.ADMIN.AUTOMATIONS })
          },
        }
      case "create_certificate":
        return {
          message: [
            { text: `Create a certificate`, bold: true },
            {
              text: `to motivate ${memberLabel.plural} to complete the ${productLabel.singular}.`,
            },
          ],
          onClick: () =>
            openCertificateDrawer({ drawerCertificatesProductId: activeProduct.id }),
        }
      case "customize_registration_page":
        return {
          message: [
            {
              text: `Customize the ${productLabel.singular} registration page`,
              bold: true,
            },
            { text: `to attract registrants.` },
          ],
          onClick: () =>
            openRegistrationDrawer({ drawerRegistrationExperienceId: activeProduct.id }),
        }
      case "review_submissions":
        if (!action.contentUsage) return null
        return {
          message: [
            { text: `Review ${action.contentUsage.content.name}`, bold: true },
            { text: `${pluralize("response", action.count)} from` },
            { text: action.count, bold: true },
            { text: action.count === 1 ? memberLabel.singular : memberLabel.plural },
          ],
          onClick: () => {
            history.push({
              pathname: contentUsagePath,
              search: setSearchParams<GlobalDrawerParams<"contentUsage">>("", {
                u: Relay.fromGlobalId(action.contentUsage!.id).id,
                drawerTab: "submissions",
              }),
            })
          },
        }
      default:
        return null
    }
  }

  function handleClick() {
    if (action.viewerHasDismissed) {
      remove({
        variables: {
          id: action.id,
        },
        updater: (store) => {
          storeUpdater(store, true)
        },
        optimisticUpdater: (store) => {
          storeUpdater(store, true)
        },
      })
    } else {
      create({
        variables: {
          id: action.id,
        },
        updater: (store) => {
          storeUpdater(store, false)
        },
        optimisticUpdater: (store) => {
          storeUpdater(store, false)
        },
      })
    }
  }

  function storeUpdater(store: RecordSourceSelectorProxy, isDeleting: boolean) {
    if (!activeProduct) return
    const productRecord = store.get(activeProduct.id)
    if (!productRecord) return
    const actions = ConnectionHandler.getConnection(
      productRecord,
      "ProductAdminDashboardActions__productAdminActions"
    )
    if (!actions) return
    const edges = actions.getLinkedRecords("edges")
    if (!edges) return
    const index = edges.findIndex((edg) => {
      const node = edg.getLinkedRecord("node")
      if (!node) return false
      return node.getDataID() === action.id
    })
    const node = edges[index].getLinkedRecord("node")
    if (!node) return
    node.setValue(!isDeleting, "viewerHasDismissed")
    Relay.reorderEdgeInConnection(actions, index, isDeleting ? 0 : edges.length - 1)
  }
}

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.measure.borderRadius.medium,
    // onDark[100] is closet to #F4F4F5
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.onDark[100],
    },
  },
  radioIcon: {
    borderRadius: "50%",
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.constants.stroke}`,
    transition: "background-color 0.2s ease-in-out",
  },
  labelContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },
  ctaContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
  checkbox: {
    "&:hover:not($checked)": {
      backgroundColor: "transparent",
    },
    "&:hover $radioIcon": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    padding: 0,
  },
  disabledCheckbox: {
    padding: 0,
    cursor: "not-allowed",
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    width: "40%",
  },
}))

export function ProductAdminDashboardActionSkeleton() {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <div className={classes.itemContainer}>
        <div className={classes.skeletonContainer}>
          <Skeleton variant={"circle"} width={24} height={24} />
          <DiscoTextSkeleton width={"80%"} />
        </div>
        <DiscoIcon icon={"arrow-right"} />
      </div>
    </Grid>
  )
}

export default Relay.withSkeleton({
  component: ProductAdminDashboardAction,
  skeleton: ProductAdminDashboardActionSkeleton,
})
