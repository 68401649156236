/**
 * @generated SignedSource<<7894ac8c18600fdc4fb28fc704f1de2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductAdminInfoToolbarFragment$data = {
  readonly id: string;
  readonly showDates: boolean;
  readonly showCountdown: boolean;
  readonly showAvailability: boolean;
  readonly product: {
    readonly type: ProductType;
    readonly childProducts: {
      readonly totalCount: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProductTypeTagFragment">;
  } | null;
  readonly " $fragmentType": "ProductAdminInfoToolbarFragment";
};
export type ProductAdminInfoToolbarFragment$key = {
  readonly " $data"?: ProductAdminInfoToolbarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductAdminInfoToolbarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductAdminInfoToolbarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showDates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCountdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeConnection",
          "kind": "LinkedField",
          "name": "childProducts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProductTypeTagFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductAdminDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "6432d51671cfbab0daa39e1ec9e6ef8c";

export default node;
